import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useActiveProjects, useProjectStatus, useShipDate } from './hooks';

function ProjectStatus({
  customerName,
  projectNumber,
  shipDate,
  lineItemId,
  steps,
}) {
  const { projectStatus, queryStatus } = useProjectStatus(steps, lineItemId);
  const { status, statusText } = useMemo(() => {
    if (queryStatus === 'loading') {
      return { status: queryStatus };
    }
    try {
      const lastStep = steps[steps.length - 1];
      const lastStepStatus = projectStatus[steps.length - 1];
      return { status: 'success', statusText: `${lastStepStatus.passed}/${lastStep.goal} ${lastStep.name}` };
    } catch (error) {
      console.error(error);
      return { status: 'error' };
    }
  }, [projectStatus, queryStatus, steps]);
  const shipDateText = useShipDate(shipDate);

  return (
    <div>
      <Box variant="awsui-key-label">{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Box>
      {status === 'loading' ? (
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      ) : null}
      {status === 'error' ? (
        <StatusIndicator type="error">Unknown status</StatusIndicator>
      ) : null}
      {status === 'success' ? (
        <Box variant="awsui-value-large">
          {statusText}
        </Box>
      ) : null}
    </div>
  );
}

ProjectStatus.propTypes = {
  customerName: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    goal: PropTypes.number.isRequired,
  })).isRequired,
};

function Summary() {
  const activeProjects = useActiveProjects(5);
  const navigate = useNavigate();

  return (
    <Container
      fitHeight
      header={(
        <Header actions={<Button variant="normal" onClick={() => { navigate('/orders/active'); }}>See all active orders</Button>}>
          Active orders
        </Header>
    )}
    >
      <SpaceBetween direction="vertical" size="l">
        {activeProjects.map((project) => (
          <ProjectStatus
            key={project.lineItemId}
            customerName={project.customerName}
            projectNumber={project.projectNumber}
            lineItemId={project.lineItemId}
            shipDate={project.shipDate}
            steps={project.steps}
          />
        ))}
      </SpaceBetween>
    </Container>
  );
}

export default Summary;
