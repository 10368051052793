import { useQuery } from '@tanstack/react-query';
import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import PropTypes from 'prop-types';
import React from 'react';

function UserEmail({ userID = null, backupEmail = null }) {
  const {
    data: userEmail, isFetching, isError, error,
  } = useQuery({
    queryKey: ['user', userID],
    queryFn: async () => {
      const functions = getFunctions();
      const getUserIDByEmail = httpsCallable(functions, 'getUserEmailByID');
      const result = await getUserIDByEmail({ userID });
      return result.data;
    },
    enabled: !!userID,
  });

  if (isFetching) {
    return <span>{`${userID} (loading email...)`}</span>;
  }

  if (isError) {
    return <span>{`${userID} (Error: ${error.message})`}</span>;
  }

  if (userEmail) {
    return <span>{userEmail}</span>;
  }

  return (
    <span>
      Anonymous User
      {backupEmail ? ` (${backupEmail})` : ''}
    </span>
  );
}

UserEmail.propTypes = {
  userID: PropTypes.string,
  backupEmail: PropTypes.string,
};

export default UserEmail;
