import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from 'reactfire';

import VersionCheck from '../VersionCheck';

// https://github.com/remix-run/react-router/blob/5dc07e20e38ecda14b6f6918998a62aa42416fe2/examples/auth/src/App.tsx#L130-L143
function RequireAuth({ children }) {
  const { data: user } = useUser();
  const location = useLocation();

  if (!user || !user.email) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <>
      <VersionCheck />
      {children}
    </>
  );
}

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RequireAuth;
