import {
  Container,
  FileUpload,
  Header,
  Spinner,
} from '@cloudscape-design/components';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useNotifications } from '../../../features/notifications';

function PurchaseOrderUploader({ orderId }) {
  const {
    setFieldValue,
    values: { purchaseOrder },
    errors: { purchaseOrder: purchaseOrderErrors },
  } = useFormikContext();
  const { addNotification } = useNotifications();

  const [selectedFiles, setSelectedFiles] = useState(() => {
    if (!purchaseOrder?.length) return [];
    // Try to convert the existing purchaseOrder to a File object for display purposes
    return purchaseOrder.map((file) => {
      const { name, type, lastModifiedTS } = file;
      return new File([new Blob([''], { type })], name, {
        type,
        lastModified: lastModifiedTS,
      });
    });
  });
  const [uploading, setUploading] = useState(false);

  // convert purchaseOrderErrors to array of strings
  const fileErrors = useMemo(() => {
    // Check if the input is an array of objects
    if (Array.isArray(purchaseOrderErrors) && purchaseOrderErrors.length > 0 && typeof purchaseOrderErrors[0] === 'object') {
      // Convert array of objects to array of strings
      return purchaseOrderErrors.map((errorObj) => {
        const errorStringArr = Object.entries(errorObj).map(([key, value]) => `${key} - ${value}`);
        return errorStringArr.join('; ');
      });
    }
    // Return the array as is if it's already an array of strings
    return purchaseOrderErrors;
  }, [purchaseOrderErrors]);

  const handleFileUploadChange = useCallback(({ detail }) => {
    if (!detail.value?.length) {
      setFieldValue('purchaseOrder', []);
      setSelectedFiles(detail.value);
      return;
    }

    setUploading(true);
    const uploadPromises = detail.value.map(async (file) => {
      const filePath = `orders/${orderId}/purchaseOrder/${file.name}`;
      const fileRef = ref(getStorage(), filePath);
      try {
        // Upload file
        await uploadBytes(fileRef, file);

        // Get download URL
        const downloadURL = await getDownloadURL(fileRef);
        // return data in the format we want to save
        return {
          downloadURL,
          lastModifiedTS: file.lastModified,
          name: file.name,
          ref: filePath,
          type: file.type,
        };
      } catch (error) {
        addNotification({
          type: 'error',
          dismissible: true,
          content: `Error uploading file to firebase: ${error.message}`,
          id: 'file-upload-error',
        });
        return {};
      }
    });

    Promise.all(uploadPromises)
      .then((data) => {
        setFieldValue('purchaseOrder', data);
        setSelectedFiles(detail.value);
      })
      .finally(() => {
        setUploading(false);
      });
  }, [setFieldValue, orderId, addNotification]);

  return (
    <Container
      header={(<Header>Upload Purchase Orders</Header>)}
    >
      {uploading && <Spinner size="large" />}
      <FileUpload
        i18nStrings={{
          uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
          dropzoneText: (e) => (e
            ? 'Drop files to upload'
            : 'Drop file to upload'),
          removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
          limitShowFewer: 'Show fewer files',
          limitShowMore: 'Show more files',
          errorIconAriaLabel: 'Error',
        }}
        accept=".pdf, .doc, .docx"
        onChange={handleFileUploadChange}
        value={selectedFiles}
        showFileLastModified
        showFileSize
        showFileThumbnail
        tokenLimit={1}
        fileErrors={fileErrors}
        constraintText="Please upload a PDF or Word document"
      />
    </Container>
  );
}
PurchaseOrderUploader.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default PurchaseOrderUploader;
