import {
  ColumnLayout,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React, { useMemo } from 'react';

import AutomationControls from '../../../components/HMI/Bonding/automation';
import AutomationStage from '../../../components/HMI/Bonding/automationStage';
import SystemStatus from '../../../components/HMI/Bonding/systemStatus';
import HeaterStatus from '../../../components/HMI/components/heaterStatus';
import HeatPipeStatus from '../../../components/HMI/components/heatPipeStatus';
import HFTS from '../../../components/HMI/components/hfts';
import OverrideControls from '../../../components/HMI/components/overrideControls';
import PIDValues from '../../../components/HMI/components/pidControls';
import PLCError from '../../../components/HMI/components/plcError';
import PLCNumberInput from '../../../components/HMI/components/plcNumberInput';
import PLCTimeInput from '../../../components/HMI/components/plcTimeInput';
import Warnings from '../../../components/HMI/components/warnings';
import { usePLCAddress, usePressHandle, useProgramHandle } from '../../../components/HMI/hooks/context';
import PLCContextProvider from '../../../features/plc/context';

const getHandles = (pressHandle, programHandle) => [
  { name: `MAIN.${pressHandle}_Mode`, type: 'INT' },
  { name: `MAIN.${pressHandle}_Bonding_Orientation`, type: 'INT' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },
  { name: `${programHandle}.AirStatus`, type: 'BOOL' },
  { name: `${programHandle}.ChillerStatus`, type: 'BOOL' },

  { name: `${programHandle}.ChipSideHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.CapSideHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.CapSideFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.CapSideRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.Strain`, type: 'BOOL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bRearBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bRearBlockTooHot`, type: 'BOOL' },

  // safety values
  { name: `${programHandle}.fbChipSideBondingHFTS.fToolTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fHotBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fHotBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fFrontBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fFrontBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fRearBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fRearBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fToolTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fHotBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fHotBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fFrontBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fFrontBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fRearBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fRearBlockTempMax`, type: 'LREAL' },

  { name: `${programHandle}.ChipSideHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideRearCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideRearCylinder`, type: 'BOOL' },

  { name: `${programHandle}.ChipSideToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideRearRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideRearRTD`, type: 'LREAL' },

  { name: `${programHandle}.CapSideHP1`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP2`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP3`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP4`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP5`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP6`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP1`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP2`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP3`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP4`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP5`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP6`, type: 'LREAL' },

  { name: `${programHandle}.bProcessAutomationEnabled`, type: 'BOOL' },
  { name: `${programHandle}.bShowObservationModal`, type: 'BOOL' },
  { name: `${programHandle}.iProcessState`, type: 'INT' },

  { name: `${programHandle}.bCapSideHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bCapSideRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bCapSideFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.bChipSideHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bChipSideRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bChipSideFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbChipSideBondingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fStandbyUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fStandbyUpperBand`, type: 'LREAL' },

  { name: `${programHandle}.fCapSideFrontLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontUpperBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearUpperBandAnnealing`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideFrontLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontUpperBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearUpperBandAnnealing`, type: 'LREAL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.fbChipSideBondingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.sFirebaseSessionID`, type: 'STRING', size: 20 },
  { name: `${programHandle}.bOverrideCylinders`, type: 'BOOL' },
  { name: `${programHandle}.bAutoCool`, type: 'BOOL' },

  // PID configurations
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  // // Shutoff timer
  { name: `${programHandle}.tShutdownTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tShutdownTimer.PT`, type: 'DWORD' },
  { name: `${programHandle}.bShutdownOverride`, type: 'BOOL' },

  // Heatpipe maxes
  { name: `${programHandle}.fbChipSideBondingHFTS.fHPMax`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fHPMax`, type: 'LREAL' },

  // Automation
  { name: `${programHandle}.tPresoakTime`, type: 'DWORD' },
  { name: `${programHandle}.fbPresoakTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tBondTime`, type: 'DWORD' },
  { name: `${programHandle}.fbBondTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tSuperheatWaitTime`, type: 'DWORD' },
  { name: `${programHandle}.fbSuperheatWaitTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tAnnealingWaitTime`, type: 'DWORD' },
  { name: `${programHandle}.fbAnnealingWaitTimer.ET`, type: 'DWORD' },

  { name: `${programHandle}.fCapSideLoadingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideLoadingTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideLoadingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideLoadingTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.fCapSideSetpointTolerance`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideSetpointTolerance`, type: 'LREAL' },

  { name: `${programHandle}.fCapSideBondingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideBondingTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideBondingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideBondingTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideAnnealingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideAnnealingTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideAnnealingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideAnnealingTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideSuperheatTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideSuperheatTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideSuperheatTolerance`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideSuperheatTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideSuperheatTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideSuperheatTolerance`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideFinalTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFinalTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFinalTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFinalTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.bInstronManualOverride`, type: 'BOOL' },

  { name: `${pressHandle}.Vacuum`, type: 'BOOL' },
];

function BondingHMI() {
  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();
  const plcAddress = usePLCAddress(pressHandle);

  const handles = useMemo(
    () => getHandles(pressHandle, programHandle),
    [pressHandle, programHandle],
  );

  return (
    <PLCContextProvider url={plcAddress} handles={handles} test={process.env.REACT_APP_ENV !== 'production'}>
      <Warnings />
      <PLCError />
      <ContentLayout header={<AutomationStage />}>
        <SpaceBetween direction="vertical" size="xl">
          <Grid gridDefinition={[
            { colspan: 12 },
            { colspan: 8 }, { colspan: 4 },
            { colspan: 12 },
          ]}
          >
            <AutomationControls />
            <Container header={<Header>HFTS heater blocks</Header>} fitHeight>
              <SpaceBetween size="xs">
                <ColumnLayout columns={3}>
                  <HeaterStatus sideHandle="ChipSide" heaterHandle="Hot" />
                  <HeaterStatus sideHandle="ChipSide" heaterHandle="Front" />
                  <HeaterStatus sideHandle="ChipSide" heaterHandle="Rear" />
                </ColumnLayout>
                <HeatPipeStatus sideHandle="ChipSide" count={6} />
                <ColumnLayout columns={3}>
                  <HeaterStatus sideHandle="CapSide" heaterHandle="Hot" />
                  <HeaterStatus sideHandle="CapSide" heaterHandle="Front" />
                  <HeaterStatus sideHandle="CapSide" heaterHandle="Rear" />
                </ColumnLayout>
                <HeatPipeStatus sideHandle="CapSide" count={6} />
              </SpaceBetween>
            </Container>
            <SystemStatus />
            <Container header={<Header>Additional settings</Header>}>
              <SpaceBetween direction="vertical" size="xl">
                <ExpandableSection headerText="Automation Config">
                  <ColumnLayout columns={3}>
                    <Grid gridDefinition={[
                      { colspan: 12 },
                      { colspan: 12 },
                      { colspan: 12 },
                      { colspan: 12 },
                    ]}
                    >
                      <PLCTimeInput label="Presoak Time" handle={`${programHandle}.tPresoakTime`} secondsOnly />
                      <PLCTimeInput label="Bond Time" handle={`${programHandle}.tBondTime`} secondsOnly />
                      <PLCTimeInput label="Superheat Wait Time" handle={`${programHandle}.tSuperheatWaitTime`} secondsOnly />
                      <PLCTimeInput label="Annealing Wait Time" handle={`${programHandle}.tAnnealingWaitTime`} secondsOnly />
                    </Grid>
                    <Grid gridDefinition={[
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 12 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 12 },
                    ]}
                    >
                      <PLCNumberInput
                        label="Chip Side Loading Temperature"
                        handle={`${programHandle}.fChipSideLoadingTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fChipSideLoadingTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Loading Temperature"
                        handle={`${programHandle}.fCapSideLoadingTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fCapSideLoadingTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Bonding Temp"
                        handle={`${programHandle}.fCapSideBondingTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fCapSideBondingTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Setpoint Tolerance Band"
                        handle={`${programHandle}.fCapSideSetpointTolerance`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Bonding Temp"
                        handle={`${programHandle}.fChipSideBondingTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fChipSideBondingTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Setpoint Tolerance Band"
                        handle={`${programHandle}.fChipSideSetpointTolerance`}
                        type="float"
                      />
                    </Grid>
                    <Grid gridDefinition={[
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 12 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 12 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 6 }, { colspan: 6 },
                      { colspan: 6 }, { colspan: 6 },
                    ]}
                    >
                      <PLCNumberInput
                        label="Chip Side Top Superheat Temperature"
                        handle={`${programHandle}.fChipSideSuperheatTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fChipSideSuperheatTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Superheat Tolerance Band"
                        handle={`${programHandle}.fChipSideSuperheatTolerance`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Superheat Temperature"
                        handle={`${programHandle}.fCapSideSuperheatTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fCapSideSuperheatTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Superheat Tolerance Band"
                        handle={`${programHandle}.fCapSideSuperheatTolerance`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Annealing Temperature"
                        handle={`${programHandle}.fChipSideAnnealingTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fChipSideAnnealingTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Annealing Temperature"
                        handle={`${programHandle}.fCapSideAnnealingTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fCapSideAnnealingTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Final Temperature"
                        handle={`${programHandle}.fChipSideFinalTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fChipSideFinalTempDisengaged`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Final Temperature"
                        handle={`${programHandle}.fCapSideFinalTemp`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="(Disengaged Temp)"
                        handle={`${programHandle}.fCapSideFinalTempDisengaged`}
                        type="float"
                      />
                    </Grid>
                  </ColumnLayout>
                </ExpandableSection>
                <ExpandableSection headerText="Cylinder Swap Config">
                  <ColumnLayout columns={3}>
                    <SpaceBetween size="m">
                      <Header variant="h3">Bonding</Header>
                      <PLCNumberInput
                        label="Cap Side Front Lower Band"
                        handle={`${programHandle}.fCapSideFrontLowerBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Front Upper Band"
                        handle={`${programHandle}.fCapSideFrontUpperBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Rear Lower Band"
                        handle={`${programHandle}.fCapSideRearLowerBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Rear Upper Band"
                        handle={`${programHandle}.fCapSideRearUpperBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Front Lower Band"
                        handle={`${programHandle}.fChipSideFrontLowerBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Front Upper Band"
                        handle={`${programHandle}.fChipSideFrontUpperBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Rear Lower Band"
                        handle={`${programHandle}.fChipSideRearLowerBandBonding`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Rear Upper Band"
                        handle={`${programHandle}.fChipSideRearUpperBandBonding`}
                        type="float"
                      />
                    </SpaceBetween>
                    <SpaceBetween size="m">
                      <Header variant="h3">Superheat</Header>
                      <PLCNumberInput
                        label="Cap Side Front Lower Band"
                        handle={`${programHandle}.fCapSideFrontLowerBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Front Upper Band"
                        handle={`${programHandle}.fCapSideFrontUpperBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Rear Lower Band"
                        handle={`${programHandle}.fCapSideRearLowerBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Rear Upper Band"
                        handle={`${programHandle}.fCapSideRearUpperBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Front Lower Band"
                        handle={`${programHandle}.fChipSideFrontLowerBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Front Upper Band"
                        handle={`${programHandle}.fChipSideFrontUpperBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Rear Lower Band"
                        handle={`${programHandle}.fChipSideRearLowerBandSuperheat`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Rear Upper Band"
                        handle={`${programHandle}.fChipSideRearUpperBandSuperheat`}
                        type="float"
                      />
                    </SpaceBetween>
                    <SpaceBetween size="m">
                      <Header variant="h3">Annealing</Header>
                      <PLCNumberInput
                        label="Cap Side Front Lower Band"
                        handle={`${programHandle}.fCapSideFrontLowerBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Front Upper Band"
                        handle={`${programHandle}.fCapSideFrontUpperBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Rear Lower Band"
                        handle={`${programHandle}.fCapSideRearLowerBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Cap Side Rear Upper Band"
                        handle={`${programHandle}.fCapSideRearUpperBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Front Lower Band"
                        handle={`${programHandle}.fChipSideFrontLowerBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Front Upper Band"
                        handle={`${programHandle}.fChipSideFrontUpperBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Rear Lower Band"
                        handle={`${programHandle}.fChipSideRearLowerBandAnnealing`}
                        type="float"
                      />
                      <PLCNumberInput
                        label="Chip Side Rear Upper Band"
                        handle={`${programHandle}.fChipSideRearUpperBandAnnealing`}
                        type="float"
                      />
                    </SpaceBetween>
                  </ColumnLayout>
                </ExpandableSection>
                <ExpandableSection headerText="Override controls">
                  <OverrideControls side1="CapSide" side2="ChipSide" />
                </ExpandableSection>
                <ExpandableSection headerText="PID controls">
                  <Grid gridDefinition={[
                    { colspan: 6 }, { colspan: 6 },
                    { colspan: 6 }, { colspan: 6 },
                    { colspan: 6 }, { colspan: 6 },
                    { colspan: 6 }, { colspan: 6 },
                  ]}
                  >
                    <PIDValues sideHandle="CapSide" heaterHandle="Front" engaged />
                    <PIDValues sideHandle="ChipSide" heaterHandle="Front" engaged />
                    <PIDValues sideHandle="CapSide" heaterHandle="Rear" engaged />
                    <PIDValues sideHandle="ChipSide" heaterHandle="Rear" engaged />
                    <PIDValues sideHandle="CapSide" heaterHandle="Front" engaged={false} />
                    <PIDValues sideHandle="ChipSide" heaterHandle="Front" engaged={false} />
                    <PIDValues sideHandle="CapSide" heaterHandle="Rear" engaged={false} />
                    <PIDValues sideHandle="ChipSide" heaterHandle="Rear" engaged={false} />
                  </Grid>
                </ExpandableSection>
                <ExpandableSection headerText="Chip Side Controls">
                  <HFTS side="ChipSide" />
                </ExpandableSection>
                <ExpandableSection headerText="Cap Side Controls">
                  <HFTS side="CapSide" />
                </ExpandableSection>
              </SpaceBetween>
            </Container>
          </Grid>
        </SpaceBetween>
      </ContentLayout>
    </PLCContextProvider>
  );
}

export default BondingHMI;
