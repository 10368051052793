import {
  Box,
  Button,
  Modal,
  TopNavigation,
} from '@cloudscape-design/components';
import { signOut } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';

import { useTheme } from '../../features/theme/themeProvider';
import OmniSearch from '../OmniSearch';
import TravelerScanner from '../QRCodeScanner';

function Navigation() {
  const auth = useAuth();
  const { data: user, status } = useUser();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const navigate = useNavigate();
  const { isDarkMode, setIsDarkMode } = useTheme();

  const handleQRCodeSuccess = useCallback((qrCode) => {
    const url = new URL(qrCode);
    navigate(url.pathname);
    setIsScannerOpen(false);
  }, [navigate]);

  if (!user || status !== 'success') {
    return null;
  }

  return (
    <>
      <Modal
        header="Scan Traveler"
        footer={(
          <Box float="right">
            <Button
              variant="link"
              onClick={() => { setIsScannerOpen(false); }}
            >
              Cancel
            </Button>
          </Box>
        )}
        visible={isScannerOpen}
        onDismiss={() => { setIsScannerOpen(false); }}
      >
        {isScannerOpen ? (
          <TravelerScanner
            id="nav-scanner"
            onQRCodeSuccess={handleQRCodeSuccess}
          />
        ) : null}
      </Modal>
      <TopNavigation
        identity={{
          onFollow: () => { navigate('/'); },
          title: <span style={{ cursor: 'pointer' }}>Parascope</span>,
          logo: { src: `${process.env.PUBLIC_URL}/badge-white.svg`, alt: 'Parascope logo' },
        }}
        utilities={[
          {
            type: 'button',
            text: 'Scan Traveler',
            iconName: 'file-open',
            ariaLabel: 'Notifications',
            disableUtilityCollapse: true,
            onClick: () => { setIsScannerOpen(true); },
          },
          {
            type: 'button',
            text: isDarkMode ? 'Light Mode' : 'Dark Mode',
            disableUtilityCollapse: true,
            onClick: () => setIsDarkMode((prev) => !prev),
          },
          {
            type: 'button',
            variant: 'link',
            iconName: 'gen-ai',
            text: 'Wishlist',
            ariaLabel: 'Parascope Wishlist',
            disableUtilityCollapse: true,
            href: 'https://form.asana.com/?k=L_SKcM8MkFECzw68gUAivw&d=1202767799562169',
            target: '_blank',
          },
          {
            type: 'button',
            text: 'Sign out',
            iconName: 'user-profile',
            ariaLabel: 'Sign out',
            disableUtilityCollapse: true,
            onClick: () => { signOut(auth); },
          },
        ]}
        search={<OmniSearch />}
      />
    </>
  );
}

export default Navigation;
