/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SpaceBetween } from '@cloudscape-design/components';
import { InspectionValues, StepTypes } from '@parallel-fluidics/constants';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

function formatMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  if (hours === 0) {
    return `${remainingMinutes} mins`;
  }
  return `${hours} hrs ${remainingMinutes} mins`;
}

// DatumWithLabel is only used in OrderHeader and OrderStep
function DatumWithLabel({
  additionalClassName = undefined,
  label,
  value,
}) {
  return (
    <p className={`datum ${additionalClassName ?? ''}`}>
      <span className="label">{label}</span>
      {value}
    </p>
  );
}
DatumWithLabel.propTypes = {
  additionalClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

// Chip is only used in OrderStep
function Chip({ chipDatum, onMouseMove = null, id = null }) {
  const getClassName = (inspection) => {
    switch (inspection) {
      case InspectionValues.NOT_INSPECTED:
        return ' toBeInspected';
      case InspectionValues.PASSED:
        return ' passed completed';
      case InspectionValues.REJECT_MAJOR:
        return ' rejectMajor completed';
      case InspectionValues.REJECT_COSMETIC:
        return ' rejectCosmetic completed';
      case InspectionValues.TUNING:
        return ' tuning completed';
      case 'On deck':
        return ' onDeck';
      case 'Nonexistent':
        return ' nonexistent';
      default:
        return '';
    }
  };
  return (
    <div className="chip" onMouseMove={onMouseMove} id={id}>
      {chipDatum.travelerId ? (
        <Link to={`/travelers/${chipDatum.travelerId}`}>
          <div className={`prevInspection${getClassName(chipDatum.prevInspection)}`} />
          <div className={`curInspection${getClassName(chipDatum.curInspection)}`}>
            <p className="visuallyHidden">{chipDatum.travelerId}</p>
          </div>
        </Link>
      ) : (
        <div className="chipWrapper">
          <div className="prevInspection nonexistent" />
          <div className="curInspection nonexistent">
            <p className="visuallyHidden">Nonexistent chip</p>
          </div>
        </div>
      )}
    </div>
  );
}
Chip.propTypes = {
  id: PropTypes.string,
  chipDatum: PropTypes.shape({
    travelerId: PropTypes.string,
    prevInspection: PropTypes.string,
    curInspection: PropTypes.string,
  }).isRequired,
  onMouseMove: PropTypes.func,
};
function OrderStep({
  uniqueKey,
  number,
  title,
  goal,
  statusData,
}) {
  const remainCounts = useMemo(
    () => Math.max(0, goal - statusData.passed),
    [goal, statusData.passed],
  );
  const containerRef = useRef(null);
  const [chipsRendered, setChipsRendered] = useState(false);
  const [rangeIdx, setRangeIdx] = useState({ start: 0, end: 0 });
  const [averageProcessTime, setAverageProcessTime] = useState(0);
  const [estimate, setEstimate] = useState(0);

  const [draggingLeft, setDraggingLeft] = useState(false);
  const [draggingRight, setDraggingRight] = useState(false);

  const groupByDate = useMemo(() => {
    const groupedData = statusData.chipData.reduce((acc, chip, index) => {
      const date = chip.sortDate.toDate();
      const key = `${date.getMonth() + 1}/${date.getDate()}`;
      if (!acc[key]) {
        acc[key] = { startIdx: index, endIdx: index };
      } else {
        acc[key].endIdx = index;
      }
      return acc;
    }, {});

    const groupedArray = Object.keys(groupedData).map((key) => ({
      date: key,
      ...groupedData[key],
    }));

    if ([StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type)) {
      let endGroupIndex = groupedArray.length - 1;
      let rangeEnd = groupedArray[endGroupIndex]?.endIdx || 0;
      let endFound = false;

      // Find endIdx by looking for the last non-"On deck" chip
      while (!endFound && endGroupIndex >= 0) {
        const group = groupedArray[endGroupIndex];
        for (let j = group.endIdx; j >= group.startIdx; j -= 1) {
          if (statusData.chipData[j].curInspection !== 'On deck') {
            rangeEnd = j;
            endFound = true;
            break;
          }
        }
        if (!endFound) {
          endGroupIndex -= 1;
        }
      }

      // After finding rangeEnd, determine rangeStart to meet minimum length
      let rangeStart = groupedArray[endGroupIndex]?.startIdx || 0;
      const minGroupLength = 5; // Minimum length for the range
      let totalLength = rangeEnd - rangeStart;
      endGroupIndex -= 1;

      // Expand start range backwards to meet minimum length if needed
      while (totalLength < minGroupLength && endGroupIndex >= 0) {
        rangeStart = groupedArray[endGroupIndex].startIdx;
        totalLength = rangeEnd - rangeStart;
        endGroupIndex -= 1;
      }
      setRangeIdx({ start: rangeStart, end: rangeEnd });
    }
    return groupedArray;
  }, [statusData.chipData, statusData.type]);

  useEffect(() => {
    if (rangeIdx.end - rangeIdx.start < 2) return;
    // calculate average process time by start to start
    const pressData = statusData.chipData.slice(rangeIdx.start, rangeIdx.end + 1)
      .reduce((acc, curChip) => {
        if (!curChip.runStartDate) return acc; // Skip if runStartDate is undefined

        const { press } = curChip;
        // eslint-disable-next-line max-len
        const prevData = acc[press] || { minTime: curChip.runStartDate, maxTime: curChip.runStartDate, count: 0 };

        // Update the min and max runStartDate for the press
        prevData.minTime = Math.min(prevData.minTime, curChip.runStartDate);
        prevData.maxTime = Math.max(prevData.maxTime, curChip.runStartDate);
        prevData.count += 1;

        acc[press] = prevData;
        return acc;
      }, {});
    const { sum, pressCount } = Object.values(pressData).reduce((
      acc,
      { minTime, maxTime, count },
    ) => {
      if (count > 1) {
        const pressAvg = (maxTime - minTime) / (count - 1);
        acc.sum += pressAvg;
        acc.pressCount += 1;
      }
      return acc;
    }, { sum: 0, pressCount: 0 });

    if (pressCount > 0) {
      const averageMinutes = (sum / pressCount) / 1000 / 60;
      setAverageProcessTime(Math.round(averageMinutes));
      setEstimate(averageMinutes * (remainCounts / ((statusData.stepYield / 100) || 1)));
    }
  }, [rangeIdx, statusData, remainCounts]);

  const getBracketStyle = useCallback((index, isRight = false) => {
    const element = document.getElementById(`${uniqueKey}_${index}`);
    const divRect = element?.getBoundingClientRect();
    const containerRect = containerRef?.current?.getBoundingClientRect();
    if (!divRect || !containerRect) return {};
    const left = isRight
      ? divRect.right - containerRect.left
      : divRect.left - containerRect.left;
    return { left, top: divRect.top - containerRect.top - 2 };
  }, [uniqueKey]);

  const handleUpdateRange = useCallback((value, type) => {
    setRangeIdx((prev) => ({
      ...prev,
      [type]: parseInt(value, 10),
    }));
  }, []);

  const handleMouseMove = useCallback((index) => {
    if (draggingLeft) {
      handleUpdateRange(Math.min(index, rangeIdx.end - 1), 'start');
    } else if (draggingRight) {
      handleUpdateRange(Math.max(index, rangeIdx.start + 1), 'end');
    }
  }, [draggingLeft, draggingRight, handleUpdateRange, rangeIdx.end, rangeIdx.start]);

  const handleMouseUp = useCallback(() => {
    setDraggingLeft(false);
    setDraggingRight(false);
  }, []);

  useEffect(() => {
    if (draggingLeft || draggingRight) {
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [draggingLeft, draggingRight, handleMouseUp]);

  useEffect(() => {
    if (chipsRendered || rangeIdx.end === 0) return;
    const checkChipsRendered = () => {
      const element = document.getElementById(`${uniqueKey}_${rangeIdx.end}`);
      if (element) {
        setChipsRendered(true);
      } else {
        requestAnimationFrame(checkChipsRendered);
      }
    };

    // Start checking after the first render
    requestAnimationFrame(checkChipsRendered);
  }, [uniqueKey, rangeIdx.end, chipsRendered]);

  return (
    <li className="orderStep">
      <div className="topRow">
        <h4>
          <span className="number">{number}</span>
          {title}
        </h4>
        {[StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type) && (
          <SpaceBetween direction="horizontal" size="m">
            <DatumWithLabel label="Average press minutes" value={`${averageProcessTime} min`} />
            {estimate > 0 && (
              <DatumWithLabel label="Estimate" value={formatMinutesToHours(estimate)} />
            )}
          </SpaceBetween>
        )}
        <DatumWithLabel label="Yield" value={`${statusData.stepYield}%`} />
      </div>
      {/* data */}
      <ul className="status">
        <li>
          <p className="datum passed">
            <span className="largeNumber">
              <span className="passed">{statusData.passed}</span>
              <span className="goal">{goal}</span>
            </span>
            <span className="label">Passed</span>
          </p>
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="rejectCosmetic"
            label="Reject (Cosmetic)"
            value={statusData.rejectCosmetic}
          />
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="rejectMajor"
            label="Reject (Major)"
            value={statusData.rejectMajor}
          />
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="tuning"
            label="Tuning"
            value={statusData.tuning}
          />
        </li>
        {statusData.toBeInspected > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="toBeInspected"
              label="To-be inspected"
              value={statusData.toBeInspected}
            />
          </li>
        )}
        {statusData.onDeck > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="onDeck"
              label="On deck"
              value={statusData.onDeck}
            />
          </li>
        )}
        {statusData.unknown > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="nonexistent"
              label="Unknown"
              value={statusData.onDeck}
            />
          </li>
        )}
      </ul>
      {/* chips */}
      <div className="chips-container" ref={containerRef}>
        {groupByDate.map(({ startIdx, endIdx, date }) => (
          <div key={date} className="chips">
            {statusData.chipData.slice(startIdx, endIdx + 1).map((chipDatum, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Chip key={`${title}_${index}`} chipDatum={chipDatum} id={`${uniqueKey}_${startIdx + index}`} onMouseMove={() => handleMouseMove(startIdx + index)} />
            ))}
            {[StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type) && (
              <div className="bottomRow" title={date}>
                <div className="border-curve" />
                {date}
              </div>
            )}
          </div>
        ))}
        {(new Array(remainCounts).fill({})).map((chipDatum, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Chip key={`${title}_${index}`} chipDatum={chipDatum} />
        ))}

        {
          [StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type)
          && (statusData.passed > 0
            || statusData.rejectCosmetic > 0
            || statusData.rejectMajor > 0
            || statusData.toBeInspected > 0
          )
          && chipsRendered // make sure chips are rendered before showing brackets
          && (
            <>
              {/* Left Bracket */}
              <div
                className="bracket left-bracket"
                style={getBracketStyle(rangeIdx.start)}
                onMouseDown={() => setDraggingLeft(true)}
              />

              {/* Right Bracket */}
              <div
                className="bracket right-bracket"
                style={getBracketStyle(rangeIdx.end, true)}
                onMouseDown={() => setDraggingRight(true)}
              />
            </>
          )
        }
      </div>
    </li>
  );
}
OrderStep.propTypes = {
  uniqueKey: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  statusData: PropTypes.shape({
    passed: PropTypes.number.isRequired,
    toBeInspected: PropTypes.number.isRequired,
    rejectCosmetic: PropTypes.number.isRequired,
    rejectMajor: PropTypes.number.isRequired,
    tuning: PropTypes.number.isRequired,
    onDeck: PropTypes.number,
    unknown: PropTypes.number,
    stepYield: PropTypes.number.isRequired,
    chipData: PropTypes.arrayOf(PropTypes.shape({
      travelerId: PropTypes.string,
      prevInspection: PropTypes.string.isRequired,
      curInspection: PropTypes.string.isRequired,
      runStartDate: PropTypes.instanceOf(Date),
    })).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderStep;
export { DatumWithLabel };
